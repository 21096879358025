import React, { Fragment } from "react";
import Link from "../common/Link/Link";
import Typography from "../common/Typography/Typography";
import withConfig from "../utils/withConfig";
import { urlToUseOnIframe } from "./halstonMediaHelper";

export const isValidString = (text) => {
  return text && text.trim() !== "";
};

export const getEnvUrl = (env) => {
  switch (env) {
    case "production":
      return "https://www.tapinto.net";
    case "preview":
      return "https://tap-preview.tapinto.net";
    case "staging":
      return "https://tap-staging.tapinto.net";
    case "development":
      return "http://localhost:3002";
    default:
      console.log(`Sorry, no env defined ${env}.`);
  }
};

export const getLogoUrl = (envUrl, townSlug, sectionSlug) => {
  let url = "";
  if (townSlug) {
    url = `${envUrl}towns/${townSlug}`;
  } else if (sectionSlug) {
    url = `${envUrl}sections/${sectionSlug}`;
  } else {
    url = `${envUrl}`;
  }
  return url;
};

export const cloneWithoutReferences = (element) => {
  return JSON.parse(JSON.stringify(element));
};

export const removeDuplicatesById = (arr) => {
  return arr.filter(
    (v, i, a) =>
      a.findIndex((t) => JSON.stringify(t.id) === JSON.stringify(v.id)) === i
  );
};

export const defineIconColor = (
  disabled,
  isSelected,
  fillColor,
  onClickColor,
  inactiveColor,
  isHovered,
  onHoverColor
) => {
  let iconColor;
  if (onClickColor && isSelected && disabled === false) {
    iconColor = onClickColor;
  } else if (
    onHoverColor &&
    isHovered &&
    isSelected === false &&
    disabled === false
  ) {
    iconColor = onHoverColor;
  } else if (inactiveColor && disabled) {
    iconColor = inactiveColor;
  } else {
    iconColor = fillColor;
  }
  return iconColor;
};

export const getCategoryUrl = (type, category, townSlug) => {
  if (townSlug) {
    return `${withConfig("HOME")}towns/${townSlug}/${type}?category_ids=${
      category.slug
    }`;
  } else {
    return `${withConfig("HOME")}${type}?category_ids=${category.slug}`;
  }
};

export const getCategoriesLinks = (
  categories,
  townSlug,
  isShowPage,
  styleProps,
  type,
  isContentPreview = false
) => {
  if (categories.length === 1) {
    return isContentPreview
      ? [
          <Typography
            bold={styleProps.bold ? styleProps.bold : false}
            level={styleProps.level ? styleProps.level : "t1_text_1"}
            color={styleProps.color ? styleProps.color : "gray"}
            className={styleProps.className ? styleProps.className : ""}
          >
            {categories[0].name}
          </Typography>,
        ]
      : [
          <Fragment key={`category-link-${categories[0].id}}`}>
            <Link
              bold={styleProps.bold ? styleProps.bold : false}
              level={styleProps.level ? styleProps.level : "t1_text_1"}
              color={styleProps.color ? styleProps.color : "gray"}
              url={getCategoryUrl(type, categories[0], townSlug)}
              children={categories[0].name}
              className={styleProps.className ? styleProps.className : ""}
            />
          </Fragment>,
        ];
  } else {
    const separator = isShowPage ? " / " : ", ";
    return categories.map((category, id) => {
      return isContentPreview ? (
        <Fragment key={`category-link-${id}}`}>
          <Typography
            bold={styleProps.bold ? styleProps.bold : false}
            level={styleProps.level ? styleProps.level : "t1_text_1"}
            color={styleProps.color ? styleProps.color : "gray"}
            className={styleProps.className ? styleProps.className : ""}
          >
            {categories.length !== id + 1
              ? category.name + separator
              : category.name}
          </Typography>
        </Fragment>
      ) : (
        <Fragment key={`category-link-${id}}`}>
          <Link
            bold={styleProps.bold ? styleProps.bold : false}
            level={styleProps.level ? styleProps.level : "t1_text_1"}
            color={styleProps.color ? styleProps.color : "gray"}
            url={getCategoryUrl(type, category, townSlug)}
            children={
              categories.length !== id + 1
                ? category.name + separator
                : category.name
            }
            className={styleProps.className ? styleProps.className : ""}
          />
        </Fragment>
      );
    });
  }
};

export const testSlug = (slug) => {
  const slugRegex = /^[a-z0-9\-]+$/;
  return slugRegex.test(slug);
};

export const getTextFromHtml = (html) => {
  const findHtmlElementsRegex = /<[^>]+>/g;
  const nonBreakingSpace = /&nbsp;/g;
  const doubleQuotationMark = /&quot;/g;
  const openingDoubleLowQuotationMark = /&ldquo;/g;
  const closingDoubleLowQuotationMark = /&rdquo;/g;
  const singleQuotationMark = /&#39;/g;
  const ampersand = /&amp;/g;
  const ampersandConsecutive = /amp;/g;
  let text = "";
  if (html) {
    text = html
      .replace(findHtmlElementsRegex, "")
      .replace(nonBreakingSpace, " ")
      .replace(doubleQuotationMark, '"')
      .replace(singleQuotationMark, "'")
      .replace(openingDoubleLowQuotationMark, "“")
      .replace(closingDoubleLowQuotationMark, "”")
      .replace(ampersand, "&")
      .replace(ampersandConsecutive, "");
  }

  return text;
};

export const isValidTime = (time) => {
  const regex_am_pm_format = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AP][M]))/g;
  if (time) {
    if (regex_am_pm_format.test(time)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const buildValidUrlShareContent = (contentUrl) => {
  return `${withConfig("HOME")}${contentUrl?.substring(1)}`;
};

export const buildFullAddress = (content) => {
  return `${content.address}, ${content.city}, ${content.state}`;
};

export const replaceIdInUrl = (url, id) => {
  return url.replace(":id", id);
};

export const sendMessageToParent = (message, envUrl) => {
  const msg = message;
  if (window.top !== window.self) {
    window.parent.postMessage(msg, envUrl);
  }
};

export const redirectToPage = (url, target) => {
  window.open(url, target);
};

export const handleSearch = (town, searchText) => {
  let searchUrl;
  const townSlug = town?.slug;

  if (town?.under_license_contract) {
    searchUrl = `${town?.host}/search`;
  } else {
    searchUrl = withConfig("SEARCH");
  }

  if (isValidString(townSlug)) {
    searchUrl = searchUrl + `?utf8=✓&id=${townSlug}&q=${searchText}`;
  } else {
    searchUrl = searchUrl + `?utf8=✓&q=${searchText}`;
  }

  if (window.top !== window.self) {
    return redirectToPage(searchUrl, "_top");
  } else {
    window.location.href = searchUrl;
  }
};

export const checkIfIsNotTheLastItem = (index, array) => {
  return index !== array.length - 1;
};

export const replaceKey = (object, oldKey, newKey) => {
  object[newKey] = object[oldKey];

  return object;
};

export const redirectToHomepage = (townSlug) => {
  let redirectUrl = withConfig("HOME");
  if (townSlug) {
    redirectUrl = `${withConfig("HOME")}towns/${townSlug}`;
  }
  redirectToPage(redirectUrl, "_top");
};

export const isValidEmail = (email) => {
  const emailRegex = /^.*[a-zA-Z]\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  if (email) {
    return emailRegex.test(email);
  } else {
    return false;
  }
};

export const isVideoPresent = (contribution) => {
  return (
    contribution.video_url !== "" &&
    contribution.video_url !== "null" &&
    contribution.video_url !== null
  );
};

export const replaceInHtmlJustText = (htmlText, textToReplace) => {
  let parsedHtmlText = "";
  if (Array.isArray(textToReplace)) {
    for (var i = 0; i < textToReplace.length; i++) {
      htmlText = htmlText?.replaceAll(
        textToReplace[i].toReplace,
        textToReplace[i].toReplaceWith
      );
    }
    parsedHtmlText = htmlText;
  } else {
    parsedHtmlText = htmlText?.replaceAll(
      textToReplace.toReplace,
      textToReplace.toReplaceWith
    );
  }
  return parsedHtmlText;
};

export const replaceInHtmlText = (htmlText, textToReplace, className) => {
  const parsedHtmlText = replaceInHtmlJustText(htmlText, textToReplace);
  return (
    <div
      dangerouslySetInnerHTML={{ __html: parsedHtmlText }}
      className={className}
    />
  );
};

export const isValidUrl = (urlString) => {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  if (urlString && urlString.trim() !== "" && urlRegex.test(urlString)) {
    return true;
  } else {
    return false;
  }
};

export const getPageNumber = (paginationPage, currentPage) => {
  return paginationPage !== undefined ? paginationPage : parseInt(currentPage);
};

export const buildRedirectUrl = ({ type, params }) => {
  const currentUrl = document.URL;
  let redirectUrl = currentUrl;
  const pageParamRegex = new RegExp(/page\=[0-9]{1,2}/);
  const paramSeparator = currentUrl.includes("?") ? "&" : "?";

  switch (type) {
    case "pagination":
      if (currentUrl.includes("page=")) {
        redirectUrl = currentUrl.replace(
          pageParamRegex,
          "page=" + params.pageNumber
        );
      } else {
        redirectUrl = currentUrl.concat(
          paramSeparator + "page=" + params.pageNumber
        );
      }
      break;
    case "categories":
      const categoriesParamRegex = new RegExp(/(\?|&)category_ids\=(.*)/);
      const categoriesParamSeparator = currentUrl.includes("?page=")
        ? "&"
        : "?";

      if (params.categorySlugs === "") {
        redirectUrl = currentUrl.replace(categoriesParamRegex, "");
      } else {
        if (currentUrl.includes("category_ids=")) {
          redirectUrl = currentUrl.replace(
            categoriesParamRegex,
            categoriesParamSeparator + "category_ids=" + params.categorySlugs
          );
        } else {
          if (currentUrl.includes("page=")) {
            redirectUrl = currentUrl
              .replace(pageParamRegex, "")
              .concat("category_ids=" + params.categorySlugs);
          } else {
            redirectUrl = currentUrl.concat(
              categoriesParamSeparator + "category_ids=" + params.categorySlugs
            );
          }
        }
      }
      break;
    default:
      break;
  }

  return redirectUrl;
};

export const buildChipDataForMultipleAutocomplete = (label, id, className) => {
  return {
    label: label,
    value: id,
    labelComponent: (
      <Typography level="t1_text_1" bold color="white">
        {label}
      </Typography>
    ),
    chipClass: className,
  };
};

export const handleModalActions = ({
  type,
  action,
  setOpenModal,
  envUrl,
  townSlug,
}) => {
  switch (type) {
    case "savedDrafts":
      setOpenModal(action === "open" ? true : false);
      if (window.top !== window.self) {
        sendMessageToParent(
          action === "open" ? "openDrafts" : "closeDrafts",
          envUrl
        );
      }
      break;
    case "submitContent":
      if (window.top !== window.self) {
        sendMessageToParent(
          "openSubmitContent",
          urlToUseOnIframe(townSlug, withConfig("HOME_MONGO"))
        );
      } else {
        setOpenModal(action === "open" ? true : false);
      }
      break;
    case "login":
      setOpenModal(action === "open" ? true : false);
      if (window.top !== window.self) {
        sendMessageToParent(
          action === "open" ? "openLogin" : "closeLogin",
          envUrl
        );
      }
      break;
    case "realEstateContactForms":
      setOpenModal(action === "open" ? true : false);
      break;
    default:
      break;
  }
};

export const isValidPhoneNumber = (phoneNumber) => {
  const regexWithCountryCode = /^\+[1-9]{1,3}[0-9]{3,14}$/;
  return regexWithCountryCode.test(phoneNumber);
};

export const townContentUrl = ({ townSlug, typeOfContent, contentSlug }) => {
  return townSlug
    ? `/towns/${townSlug}/${typeOfContent}/${contentSlug}`
    : `/${typeOfContent}/${contentSlug}`;
};

export const topLocalNewstUrl = ({ townSlug }) => {
  return townSlug
    ? `/towns/${townSlug}/articles?by_town=true&top_stories=true`
    : `/articles?by_town=true&top_stories=true`;
};

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

export const getNumberOfLines = (htmlString) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;

  const brTags = tempElement.getElementsByTagName("br").length;
  const pTags = tempElement.getElementsByTagName("p").length;
  const divTags = tempElement.getElementsByTagName("div").length;
  const liTags = tempElement.getElementsByTagName("li").length;

  const totalLines = brTags + pTags + divTags + liTags;

  return totalLines;
};

export const pathToUrl = (path, params) => {
  if (!params || !path) return path;
  let url = path;
  Object.entries(params).forEach(([key, value]) => {
    url = url.replace(`:${key}`, value);
  });
  return url;
};
// IMPORTANT: this is needed due to licenses project where the domains are dynamic
export const buildUrlBasedOnHost = () => {
  const protocol = window.location.protocol;
  const host = window.location.host;
  return protocol + "//" + host;
};

export const getUserNameInitialsFromEmailOrFullname = (value) => {
  if (!value) return "";
  const emailRegex = /@/g;
  if (emailRegex.test(value)) {
    return value.split("@")[0].slice(0, 2).toUpperCase();
  } else {
    let initials = "";
    const names = value.split(" ");
    names.forEach((name) => {
      initials += name.charAt(0).toUpperCase();
    });
    return initials.slice(0, 2);
  }
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getHeightAndWidthFromDataUrl = (dataURL) =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = dataURL;
  });

export const getRandomNumber = (min, max) => {
  return Math.round(Math.random() * (max - min)) + min;
};
export const isObjectEmpty = (obj) => {
  if (!obj) {
    return true;
  }

  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
};
