export const SUBSCRIBE_URL =
  "https://tap-legacy-preview.tapinto.net/migration_api/subscriptions/upsert_subscription";
export const FETCH_SUBSCRIPTIONS =
  "https://tap-legacy-preview.tapinto.net/migration_api/subscriptions/previous_user_subscriptions";
export const NEW_SUBSCRIPTION =
  "https://tap-preview.tapinto.net/subscriptions/new";
export const EDIT_USER_PROFILE =
  "https://tap-legacy-preview.tapinto.net/users/edit";
export const HOME = "https://tap-preview.tapinto.net/";
export const HOME_MONGO = "https://tap-legacy-preview.tapinto.net/";
export const ADMIN = "https://tap-legacy-preview.tapinto.net/admin";
export const HALSTON_MEDIA_URL =
  "https://preview.halstonmedia.com/towns/halston-media";
export const VALIDATE_RECAPTCHA_URL =
  "https://tap-preview.tapinto.net/api/v1/validate_recaptcha";
export const EXEMPTION_URL =
  "https://tap-preview.tapinto.net/api/v1/save_user_exemption.json";
export const GET_TOWNS_BY_COUNTY_URL =
  "https://tap-preview.tapinto.net/api/v1/get_towns_by_county.json";
export const GET_CATEGORIES_BY_TYPE_URL =
  "https://tap-preview.tapinto.net/api/v1/get_categories_by_content_type.json";
export const SIGN_IN = "https://tap-preview.tapinto.net/users/sign_in";
export const EVENTS_URL = "https://tap-preview.tapinto.net/events";
export const GET_EVENTS = "https://tap-preview.tapinto.net/api/v1/get_events";
export const GET_CATEGORIES_FOR_EVENTS =
  "https://tap-preview.tapinto.net/api/v1/get_categories_for_events";
export const GET_UPCOMING_EVENTS =
  "https://tap-preview.tapinto.net/api/v1/get_upcoming_events";
export const SEARCH = "https://tap-preview.tapinto.net/search";
export const GET_OBITUARIES =
  "https://tap-preview.tapinto.net/api/v1/get_obituaries";
export const GET_ALL_OBITUARIES_NAMES =
  "https://tap-preview.tapinto.net/api/v1/get_all_obituaries_names";
export const GET_OBITUARY_SERVICES =
  "https://tap-preview.tapinto.net/api/v1/get_obituary_services";
export const SUBMIT_OBITUARY_ROLE_REQUEST_URL =
  "https://tap-preview.tapinto.net/api/v1/request_obituary_contributor_role";
export const EDIT = "https://tap-preview.tapinto.net/edit";
export const GET_HAMBURGER_MENU_ITEMS =
  "https://tap-preview.tapinto.net/api/v1/masterhead/hamburger_menu_items";
export const TAPINTO_TERMS_OF_USE =
  "https://tap-preview.tapinto.net/pages/terms-of-use";
export const PRIVACY_POLICY =
  "https://tap-preview.tapinto.net/pages/privacy-policy";
export const RESET_PASSWORD_URL =
  "https://tap-preview.tapinto.net/api/v1/send_reset_password";
export const FIND_USER_URL =
  "https://tap-preview.tapinto.net/api/v1/user_exists";
export const UPGRADE_TO_CONTRIBUTOR_URL =
  "https://tap-preview.tapinto.net/api/v1/upgrade_to_contributor";
export const USER_SIGN_IN_URL = "https://tap-preview.tapinto.net/users/sign_in";
export const SEND_CONFIRMATION_EMAIL_URL =
  "https://tap-preview.tapinto.net/api/v1/send_confirmation_email_login";
export const CREATE_ACCOUNT_URL =
  "https://tap-preview.tapinto.net/api/v1/create_account";
export const LOCKED_BY =
  "https://tap-preview.tapinto.net/api/v1/articles/:id/locked_by";
export const GET_USER_DRAFTS =
  "https://tap-preview.tapinto.net/api/v1/get_user_drafts";
export const UPLOAD_FACEBOOK_PHOTO =
  "https://tap-preview.tapinto.net/api/v1/upload_facebook_photo";
export const REMOVE_PHOTO =
  "https://tap-preview.tapinto.net/api/v1/remove_photo";
export const REAL_ESTATE_LISTING_DIY =
  "https://tap-preview.tapinto.net/real_estate_listings/new";
export const REAL_ESTATES_INDEX =
  "https://tap-preview.tapinto.net/real_estate_listings";
export const REAL_ESTATES_TOWN_INDEX =
  "https://tap-preview.tapinto.net/towns/:id/real_estate_listings";
export const GET_REAL_ESTATE_SINGLE =
  "https://tap-preview.tapinto.net/api/v1/get_real_estate";
export const GET_REAL_ESTATES =
  "https://tap-preview.tapinto.net/api/v1/get_real_estates";
export const SEND_REAL_ESTATES_CONTACT_EMAIL =
  "https://tap-preview.tapinto.net/api/v1/send_contact_email";
export const LOAD_TOWNS_WITH_CLUSTERS =
  "https://tap-preview.tapinto.net/api/v1/load_towns_with_clusters";
export const LOAD_TOWNS_WITHOUT_CLUSTERS =
  "https://tap-preview.tapinto.net/api/v1/load_towns_without_clusters";
export const GET_TOP_LOCAL_NEWS_BY_TOWN =
  "https://tap-preview.tapinto.net/api/v1/towns/top_stories";
export const GET_OVERALL_TOP_LOCAL_NEWS =
  "https://tap-preview.tapinto.net/api/v1/overall/top_stories";
export const GET_TOP_LOCAL_NEWS_BY_SECTION_AND_TOWN =
  "https://tap-preview.tapinto.net/api/v1/sections/top_stories";
export const GET_DISPLAY_BLOCKS_BY_SECTION_AND_TOWN =
  "https://tap-preview.tapinto.net/api/v1/sections/display_blocks";
export const GET_DISPLAY_BLOCKS_BY_TOWN =
  "https://tap-preview.tapinto.net/api/v1/towns/display_blocks";
export const GET_SPONSORS_BY_TOWN =
  "https://tap-preview.tapinto.net/api/v1/towns/sponsors";
export const GET_VIDEO_AD =
  "https://tap-preview.tapinto.net/api/v1/towns/video_ad";
export const FOOTER_BY_TOWN =
  "https://tap-preview.tapinto.net/api/v1/towns/:id/footer";
export const ADVERTISING_POLICY =
  "https://tap-preview.tapinto.net/pages/advertising-policy";
export const FETCH_OVERALL_MASTHEAD_LINKS =
  "https://tap-preview.tapinto.net/api/v1/links_for_overall_masthead";
export const SITES_PAGE = "https://tap-preview.tapinto.net/tapinto_sites";
export const TAP_MOBILE_DEVICES_IMAGE =
  "https://tapinto-production.s3.amazonaws.com/assets/images/tap_mobile_devices_image.png";
export const FRANCHISE_YOUTUBE_VIDEO =
  "https://www.youtube.com/embed/56zSyxBQv8I";
export const OVERALL_FOOTER_LINKS =
  "https://tap-preview.tapinto.net/api/v1/overall/footer";
export const GET_TOWN_THEME =
  "https://tap-preview.tapinto.net/api/v1/towns/:id/theme";
export const LOAD_CUSTOM_MASTERHEAD_BUTTONS =
  "https://tap-preview.tapinto.net/api/v1/towns/:id/custom_masterhead_buttons";
export const LOAD_CUSTOM_MASTERHEAD_BUTTONS_WITH_NO_TOWN =
  "https://tap-preview.tapinto.net/api/v1/masterhead/load_buttons_from_theme";
export const FETCH_SIGN_UP_BOTTOM_TEXT =
  "https://tap-preview.tapinto.net/api/v1/towns/:id/sign_up_bottom_text";
export const RESET_USER_PASSWORD =
  "https://tap-preview.tapinto.net/api/v1/users/passwords/update";
export const HALSTON_MEDIA_HOST = "https://preview.halstonmedia.com";
export const LOAD_DIRECTORIES_CATEGORIES =
  "https://tap-preview.tapinto.net/api/v1/directories/load_categories";
export const LOAD_DIRECTORIES_CITIES =
  "https://tap-preview.tapinto.net/api/v1/directories/load_cities";
export const LOAD_DIRECTORIES =
  "https://tap-preview.tapinto.net/api/v1/directories";
export const UPLOAD_DOCUMENTS =
  "https://tap-preview.tapinto.net/documents/upload";
export const FETCH_DOCUMENTS =
  "https://tap-preview.tapinto.net/documents/documents_by_contribution";
export const SAVE_DOCUMENT_INFO =
  "https://tap-preview.tapinto.net/documents/save_info";
export const REMOVE_DOCUMENT_INFO =
  "https://tap-preview.tapinto.net/documents/remove_document";
export const HALSTON_MEDIA_SIGN_IN =
  "https://preview.halstonmedia.com/users/sign_in";
export const UPLOAD_EXIT_INTENT_PHOTO =
  "https://tap-preview.tapinto.net/api/v1/photos/upload_exit_intent_photo";
export const REMOVE_EXIT_INTENT_PHOTO =
  "https://tap-preview.tapinto.net/api/v1/photos/remove_exit_intent_photo";
export const UPLOAD_CMS_CONTENT_VIDEOS =
  "https://api-workers-preview.tapinto.net/api/v1/uploader";
export const GAM_ID = "26641674";
 