import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./Button.styles";
import { useTextColorStyles } from "../style/textColor";
import clsx from "clsx";
import ButtonMaterial from "@material-ui/core/Button";
import { colorReference } from "../Theme/themes/default";

const Button = ({
  level,
  children,
  color,
  className,
  bold,
  underlined,
  variant,
  onClick,
  disabled,
  id,
  classes,
  dataCy,
  ariaLabel,
  type,
  style,
  startIcon,
  endIcon,
  size,
  fullWidth,
  ...rest
}) => {
  const customClasses = useStyles({ color });
  const textColorClasses = useTextColorStyles();

  const getChildren = () => {
    return bold ? <strong>{children}</strong> : children;
  };

  const elements = {
    button: (
      <ButtonMaterial
        className={clsx(
          customClasses.button,
          customClasses.mont_bold,
          customClasses.font_size_12_px,
          className,
          textColorClasses[color],
          underlined && customClasses.underlined
        )}
        startIcon={startIcon}
        variant={variant}
        color={color}
        onClick={onClick}
        disabled={disabled}
        id={id}
        classes={classes}
        aria-label={ariaLabel}
      >
        {getChildren()}
      </ButtonMaterial>
    ),
    simple_button: (
      <ButtonMaterial
        className={clsx(
          customClasses.simple_button,
          customClasses.mont_bold,
          customClasses.font_size_10_px,
          className,
          textColorClasses[color],
          underlined && customClasses.underlined
        )}
        startIcon={startIcon}
        endIcon={endIcon}
        variant={variant}
        onClick={onClick}
        disabled={disabled}
        id={id}
        classes={classes}
        style={style}
        data-cy={dataCy}
        aria-label={ariaLabel}
        type={type}
      >
        {getChildren()}
      </ButtonMaterial>
    ),
    cms_button: (
      <ButtonMaterial
        fullWidth={fullWidth}
        className={clsx(customClasses.cms_button, className)}
        size={size}
        variant={variant}
        onClick={onClick}
        disabled={disabled}
        id={id}
        classes={classes}
        data-cy={dataCy}
        aria-label={ariaLabel}
        type={type}
        endIcon={endIcon}
        startIcon={startIcon}
        style={style}
        {...rest}
      >
        {getChildren()}
      </ButtonMaterial>
    ),
    legal_notice_button: (
      <ButtonMaterial
        className={clsx(
          customClasses.legal_notice_submit_button,
          customClasses.mont_bold,
          customClasses.font_size_12_px,
          className,
          textColorClasses[color],
          underlined && customClasses.underlined
        )}
        type={type}
        color="primary"
        onClick={onClick}
      >
        {children}
      </ButtonMaterial>
    ),
    cms_outlined_button: (
      <ButtonMaterial
        className={className}
        onClick={onClick}
        disabled={disabled}
        id={id}
        classes={classes}
        data-cy={dataCy}
        aria-label={ariaLabel}
        type={type}
        variant={"outlined"}
        style={style}
      >
        {getChildren()}
      </ButtonMaterial>
    ),
  };
  return children ? elements[level] : null;
};

Button.propTypes = {
  level: PropTypes.oneOf([
    "button",
    "simple_button",
    "cms_button",
    "legal_notice_button",
    "cms_outlined_button",
  ]),
  color: PropTypes.oneOfType([
    PropTypes.oneOf(colorReference),
    PropTypes.string,
  ]),
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  underlined: PropTypes.bool,
  bold: PropTypes.bool,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  dataCy: PropTypes.string,
  classes: PropTypes.object,
  ariaLabel: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

export default Button;
