import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Typography from "../../common/Typography/Typography";
import { useStyles } from "./TextField.style";
import { TextField as TextFieldMaterial } from "@material-ui/core";
import { screenWidthIsLowerThan } from "../../hooks/useMediaQuery";
import { format, parseISO } from "date-fns";

const TextField = ({
  className,
  onChange,
  placeholder,
  error,
  label,
  variant,
  isMultiline,
  type,
  defaultValue,
  InputProps,
  disabled,
  labelColor,
  sublabel,
  required,
  labelLevel,
  inputProps,
  helperText,
  onKeyPress,
  boldLabel,
  autoFocus,
  id,
  value,
  nativeLabel,
  useDefaultClasses,
  customClasses,
  useNativeError,
  autoComplete,
  fullWidth,
  name,
  labelComponent,
  disablePast,
}) => {
  const classes = useStyles();
  const minDate = disablePast
    ? format(
        parseISO(new Date().toISOString()).setMinutes(0),
        "yyyy-MM-dd'T'HH:mm"
      )
    : null;

  const getClassName = () => {
    return useDefaultClasses
      ? clsx(
          error && !isMultiline && classes.error_input_field,
          error && isMultiline && classes.error_text_area,
          isMultiline ? classes.text_area : classes.input_field,
          className && className
        )
      : className;
  };

  const getLabelColor = () => {
    if (labelColor) {
      return labelColor;
    }
    return "gray";
  };

  const getLabelLevel = () => {
    if (labelLevel) {
      return labelLevel;
    } else {
      return screenWidthIsLowerThan(900) ? "medium_17_px" : "h4";
    }
  };

  return (
    <>
      {label && (
        <Typography
          level={getLabelLevel()}
          bold={boldLabel}
          color={error ? "red" : getLabelColor()}
        >
          {required ? label + "*" : label}
        </Typography>
      )}
      {sublabel && (
        <div>
          <Typography level="t1_text_1" color={error ? "red" : getLabelColor()}>
            {sublabel}
          </Typography>
        </div>
      )}

      {labelComponent && labelComponent}
      <TextFieldMaterial
        id={id}
        name={name}
        className={getClassName()}
        onChange={onChange}
        placeholder={placeholder}
        variant={variant}
        multiline={isMultiline}
        type={type}
        fullWidth={fullWidth}
        {...(defaultValue && { defaultValue: defaultValue })}
        {...(useNativeError && { error: error })}
        InputProps={{
          inputProps: {
            min: minDate,
            ...(InputProps?.inputProps ?? {}),
          },
          ...InputProps,
        }}
        inputProps={inputProps}
        disabled={disabled}
        helperText={helperText}
        onKeyPress={onKeyPress}
        autoFocus={autoFocus}
        label={nativeLabel}
        classes={customClasses}
        autoComplete={autoComplete ? true : "off"}
        value={value}
        required={required}
      />
    </>
  );
};

TextField.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.node,
  variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
  isMultiline: PropTypes.bool,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  InputProps: PropTypes.any,
  disabled: PropTypes.bool,
  labelColor: PropTypes.string,
  sublabel: PropTypes.string,
  required: PropTypes.bool,
  labelLevel: PropTypes.string,
  inputProps: PropTypes.any,
  helperText: PropTypes.string,
  onKeyPress: PropTypes.func,
  boldLabel: PropTypes.bool,
  autoFocus: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nativeLabel: PropTypes.string,
  useDefaultClasses: PropTypes.bool,
  customClasses: PropTypes.object,
  useNativeError: PropTypes.bool,
  autoComplete: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  name: PropTypes.string,
  labelComponent: PropTypes.node,
};

TextField.defaultProps = {
  variant: "outlined",
  multiline: false,
  type: "text",
  defaultValue: "",
  required: false,
  boldLabel: true,
  useDefaultClasses: true,
  className: "",
  useNativeError: false,
  autoComplete: false,
  required: false,
};

export default TextField;
