const reservationsLabels = {
  NEW_RESERVATION: "Create New Reservation",
  GO_TO_FILE: "Go To File",
  EDIT_RESERVATION: "Edit Reservation",
  DELETE_RESERVATION_CONFIRMATION_TITLE: "Delete Reservation",
  DELETE_RESERVATION_CONFIRMATION_MESSAGE:
    "Are you sure you want to delete this reservation?",
};

export default reservationsLabels;
