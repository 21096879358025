const notesLabels = {
  USER_NOTES: "User notes",
  AUTOMATED_NOTES: "Automated notes",
  CREATE_A_NEW_NOTE: "Create a new note",
  NOTE_NOT_SAVED:
    "There was an error saving your note, please try again later.",
  NOTE_SAVED: "Your note was saved!",
  NO_USER_NOTES_FOUND: "No user-generated notes have been found!",
  NO_NOTES_FOUND: "No notes have been found!",
  CREATE_A_NOTE: "Use the form below to create a new note.",
};

export default notesLabels;
